import React from "react";
import bag from "../../src/img/Error2.png";
import { Heading } from "@reliance/design-system";
import { Button } from "@reliance/design-system";
import { logAnalyticsEvent } from "../firebaseInit";
import { eventName } from "../services/constants";

export default ({
  onClick,
  error,
  ERRORTYPE,
  handleGoBack,
  tryAgain,
  ...props
}) => {
  const { type, message } = error;
  const { prescriptionId } = props;

  logAnalyticsEvent(eventName.VIEW_PRESCRIPTION_FULFILLMENT_ERROR, {
    item_name: prescriptionId,
    content:
      type === ERRORTYPE.deleted
        ? "Prescription deleted"
        : message
        ? message
        : "Something went wrong",
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 10,
        marginBottom: 15,
        marginTop: 15,
      }}
    >
      <img src={bag} alt="" width={40} />

      {type === ERRORTYPE.deleted && (
        <>
          <Heading
            level={"h4"}
            style={{ textAlign: "center", marginBottom: 10 }}
          >
            Prescription Deleted
          </Heading>

          <div>
            The doctor has deleted this prescription, you can not proceed.
            Please chat them to learn why
          </div>

          <Button style={{ width: "80%" }} onClick={onClick}>
            Back to Consultation
          </Button>
        </>
      )}
      {type === ERRORTYPE.other && (
        <>
          <Heading level="h4" style={{ textAlign: "center", marginBottom: 10 }}>
            Something’s wrong
          </Heading>
          <div style={{ fontSize: 16 }}>
            {message ||
              "Ooops, we encountered an error and you will not be able to proceed with your prescriptions right nowx"}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              width: "100%",
            }}
          >
            <Button
              variant={"outline"}
              onClick={handleGoBack}
              style={{ width: "48%", fontSize: 15, height: 40 }}
            >
              Cancel
            </Button>
            <Button
              onClick={tryAgain}
              style={{ width: "48%", fontSize: 15, height: 40 }}
            >
              Try Again
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
